
import { defineComponent } from "vue";

export default defineComponent({
    name: "Modal",

    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
    },

    setup() {
        return {};
    },
});
