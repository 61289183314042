<template>
    <div class="home">
        <NavBar />
        <Header />
        <Products />
        <About />
        <SignUp />
        <Contact />
        <Footer />

        <Modal :show-modal="false" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import NavBar from "@/components/NavBar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/utils/Modal.vue";

import About from "@/components/sections/About.vue";
import Contact from "@/components/sections/Contact.vue";
import Products from "@/components/sections/Products.vue";
import SignUp from "@/components/sections/SignUp.vue";

export default defineComponent({
    name: "Home",
    components: {
        NavBar,
        Header,
        Footer,
        Modal,
        About,
        Contact,
        Products,
        SignUp,
    },
});
</script>
