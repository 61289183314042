
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
    name: "NavBar",

    setup() {
        const isOnTop = ref(true);

        function onScroll() {
            isOnTop.value = document.documentElement.scrollTop < 100;
        }

        onMounted(() => {
            window.addEventListener("scroll", onScroll);
        });

        return {
            isOnTop,
        };
    },
});
