
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
    name: "IntersectionObserver",

    emits: ["intersecting"],

    setup(_props, { emit }) {
        const observerRef = ref<HTMLElement | null>(null);

        function intersectionHandler(entries: IntersectionObserverEntry[]) {
            if (entries[0].isIntersecting) {
                emit("intersecting");
            }
        }

        onMounted(() => {
            const intObserver = new IntersectionObserver(intersectionHandler);

            if (observerRef.value) {
                intObserver.observe(observerRef.value);
            }
        });

        return {
            observerRef,
        };
    },
});
