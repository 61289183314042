<template>
    <div
        class="modal-backdrop fade"
        :class="{ show: showModal }"
        @click="closeModal"
    ></div>
    <div
        class="modal fade"
        :class="{ show: showModal }"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <!-- <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> -->
                <div class="modal-body">
                    <img
                        class="img-fluid"
                        src="@/assets/img/mothers_day_promotion.jpg"
                    />
                </div>
                <!-- <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    <button type="button" class="btn btn-primary">
                        Save changes
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Modal",

    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
    },

    setup() {
        return {};
    },
});
</script>

<style lang="scss" scoped>
.modal {
    &.show {
        display: block;
        opacity: 1;
    }
}

.modal-backdrop {
    &.show {
        opacity: 0.5;
    }
}
</style>
