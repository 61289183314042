
import IntersectionObserver from "@/components/utils/IntersectionObserver.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "Products",

    components: {
        IntersectionObserver,
    },

    setup() {
        const showFirstProduct = ref(false);
        const showSecondProduct = ref(false);

        function showFirst() {
            showFirstProduct.value = true;
        }

        function showSecond() {
            showSecondProduct.value = true;
        }

        return {
            showFirstProduct,
            showSecondProduct,
            showFirst,
            showSecond,
        };
    },
});
