<template>
    <section class="products-section bg-black" id="products">
        <div class="container">
            <div class="row justify-content-center no-gutters mb-5 mb-lg-20">
                <div class="col-lg-6">
                    <IntersectionObserver @intersecting="showFirst">
                        <transition name="fade">
                            <img
                                v-if="showFirstProduct"
                                class="img-fluid"
                                src="@/assets/img/demo_image_01.png"
                                alt=""
                            />
                        </transition>
                    </IntersectionObserver>
                </div>
                <div class="col-lg-6">
                    <div class="bg-black text-center h-100 project">
                        <div class="d-flex h-100">
                            <div
                                class="project-text w-100 my-auto text-center text-lg-left"
                            >
                                <h4 class="text-white">Misty</h4>
                                <p class="mb-0 text-white-50">
                                    An example of where you can put an image of
                                    a project, or anything else, along with a
                                    description.
                                </p>
                                <hr class="d-none d-lg-block mb-0 ml-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center no-gutters mb-5 mb-lg-0">
                <div class="col-lg-6">
                    <div class="bg-black text-center h-100 project">
                        <div class="d-flex h-100">
                            <div
                                class="project-text w-100 my-auto text-center text-lg-left"
                            >
                                <h4 class="text-white">Misty</h4>
                                <p class="mb-0 text-white-50">
                                    An example of where you can put an image of
                                    a project, or anything else, along with a
                                    description.
                                </p>
                                <hr class="d-none d-lg-block mb-0 ml-0" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <IntersectionObserver @intersecting="showSecond">
                        <transition name="fade">
                            <img
                                v-if="showSecondProduct"
                                class="img-fluid"
                                src="@/assets/img/demo_image_02.png"
                                alt=""
                            />
                        </transition>
                    </IntersectionObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import IntersectionObserver from "@/components/utils/IntersectionObserver.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "Products",

    components: {
        IntersectionObserver,
    },

    setup() {
        const showFirstProduct = ref(false);
        const showSecondProduct = ref(false);

        function showFirst() {
            showFirstProduct.value = true;
        }

        function showSecond() {
            showSecondProduct.value = true;
        }

        return {
            showFirstProduct,
            showSecondProduct,
            showFirst,
            showSecond,
        };
    },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 2.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

#products {
    &.products-section {
        padding: 10rem 0;

        .featured-text {
            padding: 2rem;
            @include media-breakpoint-up(lg) {
                padding: 0 0 0 2rem;
                border-left: 0.5rem solid $primary;
            }
        }
        .project-text {
            padding: 3rem;
            font-size: 90%;
            @include media-breakpoint-up(lg) {
                padding: 5rem;
                hr {
                    border-color: $primary;
                    border-width: 0.25rem;
                    width: 30%;
                }
            }
        }
    }
}
</style>
